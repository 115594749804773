@font-face {
  font-family: "Chiller";
  src: local("Chiller"), url(./assets/fonts/Chiller.woff2) format("woff2");
}

@font-face {
  font-family: "Bauhaus 93";
  src: local("Bauhaus 93"), url(./assets/fonts/Bauhaus_93.woff2) format("woff2");
}
@font-face {
  font-family: "Curlz MT";
  src: local("Curlz MT"), url(./assets/fonts/Curlz_MT.woff2) format("woff2");
}
@font-face {
  font-family: "Georgia";
  src: local("Georgia"), url(./assets/fonts/Georgia.woff2) format("woff2");
}

@font-face {
  font-family: "Bernard MT Condensed";
  src: local("Bernard MT Condensed"),
    url(./assets/fonts/Bernard_MT_Condensed.woff2) format("woff2");
}

@font-face {
  font-family: "Bradley Hand ITC Std";
  src: local("Bradley Hand ITC Std"),
    url(./assets/fonts/Bradley_Hand_ITC_Std.woff2) format("woff2");
}

@font-face {
  font-family: "Ravie";
  src: local("Ravie"), url(./assets/fonts/Ravie.woff2) format("woff2");
}

@font-face {
  font-family: "Century";
  src: local("Century"), url(./assets/fonts/Century.woff2) format("woff2");
}

@font-face {
  font-family: "Harrington";
  src: local("Harrington"), url(./assets/fonts/Harrington.woff2) format("woff2");
}
